var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    {
      staticClass: "tw-relative xl:tw-pr-5",
      staticStyle: { "background-color": "rgba(26, 106, 114, 0.02)" },
    },
    [
      _c(
        "b-col",
        {
          staticClass: "tw-transition-all",
          attrs: {
            cols: "12",
            lg: _vm.isRecentViewOpen ? "8" : "12",
            xl: _vm.isRecentViewOpen ? "9" : "12",
          },
        },
        [
          _c(
            "b-row",
            [
              _vm.transferredPatientList.length > 0
                ? _c(
                    "b-col",
                    { staticClass: "tw-py-6", attrs: { cols: "12" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "tw-flex tw-justify-between tw-bg-[#ffecca] tw-text-[#ff7a37] tw-px-3 tw-py-2",
                        },
                        [
                          _c("p", { staticClass: "tw-mb-0" }, [
                            _vm._v(
                              " You have " +
                                _vm._s(_vm.transferredPatientList.length) +
                                " transferred patients ready to be prescribed "
                            ),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _c("b-col", { attrs: { cols: "12" } }, [
                _c(
                  "ul",
                  {
                    staticClass:
                      "list-unstyled xl:tw-grid-cols-3 tw-space-x-4 tw-hidden xl:tw-grid",
                  },
                  [
                    _c(
                      "li",
                      [
                        _c("CardDashboard", {
                          attrs: {
                            title: "intake",
                            counter: _vm.dashboardData.newpatient || 0,
                            "is-busy": !_vm.hasDashboardData,
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "card-dashboard-list" },
                          [
                            _vm._l(
                              _vm.transferredPatientList,
                              function (patient) {
                                return _c("CardDashboardItem", {
                                  key: patient.id,
                                  attrs: {
                                    "bg-color": "migrate",
                                    name: patient.name,
                                    "time-stamp": patient.distanceToNow,
                                    location: `${patient.state}`,
                                    date: patient.birthday,
                                    "patient-info": patient,
                                    "button-text": "Prescribe",
                                    snoozed: true,
                                    "snoozed-text": `Transferred from ${patient.transferredfrom}`,
                                  },
                                  on: { "intake-error": _vm.onIntakeError },
                                })
                              }
                            ),
                            _vm._l(_vm.newPatientsList, function (patient) {
                              return _c("CardDashboardItem", {
                                key: patient.id,
                                attrs: {
                                  "bg-color":
                                    patient.action === "Pending Message"
                                      ? "message"
                                      : "unread",
                                  name: patient.name,
                                  "time-stamp": patient.distanceToNow,
                                  location: `${patient.state}`,
                                  date: patient.birthday,
                                  "patient-info": patient,
                                  "button-text":
                                    patient.action === "Verify ID"
                                      ? "Verify"
                                      : patient.action === "Pending Message"
                                      ? "Message"
                                      : "Accept",
                                  snoozed: ["Pending Message"].includes(
                                    patient.action
                                  ),
                                  "snoozed-text":
                                    patient.action === "Pending Message"
                                      ? "Pending Action"
                                      : "",
                                },
                                on: { "intake-error": _vm.onIntakeError },
                              })
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      [
                        _c("CardDashboard", {
                          attrs: {
                            title: "messages",
                            counter: _vm.newMessageList.length,
                            "is-busy": !_vm.hasDashboardData,
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "card-dashboard-list" },
                          _vm._l(_vm.newMessageList, function (patient) {
                            return _c("CardDashboardItem", {
                              key: [
                                "Requested Blossom",
                                "Requested Estriol Cream with Tretinoin",
                              ].includes(patient.status)
                                ? `prescription-${patient.id}`
                                : patient.id,
                              attrs: {
                                "bg-color": [
                                  "Requested Blossom",
                                  "Requested Estriol Cream with Tretinoin",
                                ].includes(patient.status)
                                  ? "prescribe"
                                  : patient.status === "New Admin Message"
                                  ? "admin-message"
                                  : "unread",
                                name: patient.name,
                                "time-stamp": patient.distanceToNow,
                                location: `${patient.state}`,
                                date: patient.birthday,
                                "patient-info": patient,
                                clearBtn: true,
                                snoozeBtn: [
                                  "Requested Blossom",
                                  "Requested Estriol Cream with Tretinoin",
                                ].includes(patient.status)
                                  ? false
                                  : true,
                                "button-text": [
                                  "Requested Blossom",
                                  "Requested Estriol Cream with Tretinoin",
                                ].includes(patient.status)
                                  ? "View Profile"
                                  : "Reply",
                                snoozed: [
                                  "Snoozed",
                                  "Requested Blossom",
                                  "Requested Estriol Cream with Tretinoin",
                                ].includes(patient.status)
                                  ? true
                                  : false,
                                "snoozed-text": _vm.snoozedText(patient),
                              },
                              on: {
                                "clear-message": _vm.onClearMessage,
                                "snooze-message": _vm.onSnoozeMessage,
                                "ignore-patient": _vm.openIgnoreModal,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "message",
                                    fn: function () {
                                      return [
                                        ![
                                          "Requested Blossom",
                                          "Requested Estriol Cream with Tretinoin",
                                        ].includes(patient.status)
                                          ? _c(
                                              "div",
                                              { staticClass: "mb-3" },
                                              _vm._l(
                                                patient.latestmessages
                                                  .slice(0, 2)
                                                  .reverse(),
                                                function (
                                                  { message, sender },
                                                  i
                                                ) {
                                                  return _c("div", { key: i }, [
                                                    _c(
                                                      "p",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "line-clamp",
                                                            rawName:
                                                              "v-line-clamp",
                                                            value: 2,
                                                            expression: "2",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "mb-0 tw-text-[12px]",
                                                      },
                                                      [
                                                        sender === "PATIENT"
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-black",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      `${patient.name}: ${message}`
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : sender === "ADMIN"
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-black",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      `Admin: ${message}`
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-muted",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      `You: ${message}`
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                      ]
                                                    ),
                                                  ])
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      [
                        _c("CardDashboard", {
                          attrs: {
                            title: "follow up",
                            counter: _vm.followUpList.length,
                            "is-busy": !_vm.hasDashboardData,
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "card-dashboard-list" },
                          _vm._l(_vm.followUpList, function (patient) {
                            return _c("CardDashboardItem", {
                              key: [
                                "Requested Blossom",
                                "Requested Estriol Cream with Tretinoin",
                              ].includes(patient.status)
                                ? `prescription-${patient.id}`
                                : `followup-${patient.id}`,
                              attrs: {
                                "bg-color": [
                                  "Requested Blossom",
                                  "Requested Estriol Cream with Tretinoin",
                                ].includes(patient.status)
                                  ? "prescribe"
                                  : "unread",
                                name: patient.name,
                                "time-stamp": patient.distanceToNow,
                                location: `${patient.state}`,
                                date: patient.birthday,
                                "patient-info": patient,
                                "button-text": [
                                  "Requested Blossom",
                                  "Requested Estriol Cream with Tretinoin",
                                ].includes(patient.status)
                                  ? "View Profile"
                                  : "Mark as Complete",
                                ignoreBtn: [
                                  "Requested Blossom",
                                  "Requested Estriol Cream with Tretinoin",
                                ].includes(patient.status),
                                "selected-patient":
                                  _vm.selectedFollowupPatientInfo,
                              },
                              on: {
                                "ignore-patient": _vm.openIgnoreModal,
                                "update:selectedPatient": function ($event) {
                                  _vm.selectedFollowupPatientInfo = $event
                                },
                                "update:selected-patient": function ($event) {
                                  _vm.selectedFollowupPatientInfo = $event
                                },
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "tw-block xl:tw-hidden" },
                  [
                    _c(
                      "ul",
                      { staticClass: "list-unstyled tw-grid tw-grid-cols-3" },
                      _vm._l(_vm.tabList, function (tab) {
                        return _c(
                          "li",
                          {
                            key: tab.id,
                            staticClass: "tw-py-3 tw-capitalize",
                            class:
                              _vm.activeTab == tab.id
                                ? "dashboard-tab-active tw-border tw-border-solid tw-border-t-0 tw-border-l-0 tw-border-r-0"
                                : "tw-opacity-25",
                            on: {
                              click: function ($event) {
                                _vm.activeTab = tab.id
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(tab.text) + " ")]
                        )
                      }),
                      0
                    ),
                    _vm.activeTab == "intake"
                      ? [
                          _c(
                            "div",
                            {
                              staticClass:
                                "heading tw-text-[44px] tw-leading-[40px] tw-tracking-[0.01em] tw-py-8 tw-px-2",
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.dashboardData.newpatient) + " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "card-dashboard-list" },
                            [
                              _vm._l(
                                _vm.transferredPatientList,
                                function (patient) {
                                  return _c("CardDashboardItem", {
                                    key: patient.id,
                                    attrs: {
                                      "bg-color": "migrate",
                                      name: patient.name,
                                      "time-stamp": patient.distanceToNow,
                                      location: `${patient.state}`,
                                      date: patient.birthday,
                                      "patient-info": patient,
                                      "button-text": "Prescribe",
                                      snoozed: true,
                                      "snoozed-text": `Transferred from ${patient.transferredfrom}`,
                                    },
                                    on: { "intake-error": _vm.onIntakeError },
                                  })
                                }
                              ),
                              _vm._l(_vm.newPatientsList, function (patient) {
                                return _c("CardDashboardItem", {
                                  key: patient.id,
                                  attrs: {
                                    "bg-color":
                                      patient.action === "Pending Message"
                                        ? "message"
                                        : "unread",
                                    name: patient.name,
                                    "time-stamp": patient.distanceToNow,
                                    location: `${patient.state}`,
                                    date: patient.birthday,
                                    "patient-info": patient,
                                    "button-text":
                                      patient.action === "Verify ID"
                                        ? "Verify"
                                        : patient.action === "Pending Message"
                                        ? "Message"
                                        : "Accept",
                                    snoozed: ["Pending Message"].includes(
                                      patient.action
                                    ),
                                    "snoozed-text":
                                      patient.action === "Pending Message"
                                        ? "Pending Action"
                                        : "",
                                  },
                                  on: { "intake-error": _vm.onIntakeError },
                                })
                              }),
                            ],
                            2
                          ),
                        ]
                      : _vm._e(),
                    _vm.activeTab == "messages"
                      ? [
                          _c(
                            "div",
                            {
                              staticClass:
                                "heading tw-text-[44px] tw-leading-[40px] tw-tracking-[0.01em] tw-py-8 tw-px-2",
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.newMessageList.length) + " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "card-dashboard-list" },
                            _vm._l(_vm.newMessageList, function (patient) {
                              return _c("CardDashboardItem", {
                                key: [
                                  "Requested Blossom",
                                  "Requested Estriol Cream with Tretinoin",
                                ].includes(patient.status)
                                  ? `prescription-${patient.id}`
                                  : patient.id,
                                attrs: {
                                  "bg-color": [
                                    "Requested Blossom",
                                    "Requested Estriol Cream with Tretinoin",
                                  ].includes(patient.status)
                                    ? "prescribe"
                                    : patient.status === "New Admin Message"
                                    ? "admin-message"
                                    : "unread",
                                  name: patient.name,
                                  "time-stamp": patient.distanceToNow,
                                  location: `${patient.state}`,
                                  date: patient.birthday,
                                  "patient-info": patient,
                                  clearBtn: true,
                                  snoozeBtn: [
                                    "Requested Blossom",
                                    "Requested Estriol Cream with Tretinoin",
                                  ].includes(patient.status)
                                    ? false
                                    : true,
                                  "button-text": [
                                    "Requested Blossom",
                                    "Requested Estriol Cream with Tretinoin",
                                  ].includes(patient.status)
                                    ? "View Profile"
                                    : "Reply",
                                  snoozed: [
                                    "Snoozed",
                                    "Requested Blossom",
                                    "Requested Estriol Cream with Tretinoin",
                                  ].includes(patient.status)
                                    ? true
                                    : false,
                                  "snoozed-text": _vm.snoozedText(patient),
                                },
                                on: {
                                  "clear-message": _vm.onClearMessage,
                                  "snooze-message": _vm.onSnoozeMessage,
                                  "ignore-patient": _vm.openIgnoreModal,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "message",
                                      fn: function () {
                                        return [
                                          [
                                            "Requested Blossom",
                                            "Requested Estriol Cream with Tretinoin",
                                          ].includes(patient.status)
                                            ? _c(
                                                "div",
                                                { staticClass: "mb-3" },
                                                _vm._l(
                                                  patient.latestmessages
                                                    .slice(0, 2)
                                                    .reverse(),
                                                  function (
                                                    { message, sender },
                                                    i
                                                  ) {
                                                    return _c(
                                                      "div",
                                                      { key: i },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "line-clamp",
                                                                rawName:
                                                                  "v-line-clamp",
                                                                value: 2,
                                                                expression: "2",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "mb-0 tw-text-[12px]",
                                                          },
                                                          [
                                                            sender === "PATIENT"
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-black",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          `${patient.name}: ${message}`
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              : sender ===
                                                                "ADMIN"
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-black",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          `Admin: ${message}`
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-muted",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          `You: ${message}`
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              })
                            }),
                            1
                          ),
                        ]
                      : _vm._e(),
                    _vm.activeTab == "followup"
                      ? [
                          _c(
                            "div",
                            {
                              staticClass:
                                "heading tw-text-[44px] tw-leading-[40px] tw-tracking-[0.01em] tw-py-8 tw-px-2",
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.followUpList.length) + " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "card-dashboard-list" },
                            _vm._l(_vm.followUpList, function (patient) {
                              return _c("CardDashboardItem", {
                                key: [
                                  "Requested Blossom",
                                  "Requested Estriol Cream with Tretinoin",
                                ].includes(patient.status)
                                  ? `prescription-${patient.id}`
                                  : `followup-${patient.id}`,
                                attrs: {
                                  "bg-color": [
                                    "Requested Blossom",
                                    "Requested Estriol Cream with Tretinoin",
                                  ].includes(patient.status)
                                    ? "prescribe"
                                    : "unread",
                                  name: patient.name,
                                  "time-stamp": patient.distanceToNow,
                                  location: `${patient.state}`,
                                  date: patient.birthday,
                                  "patient-info": patient,
                                  "button-text": [
                                    "Requested Blossom",
                                    "Requested Estriol Cream with Tretinoin",
                                  ].includes(patient.status)
                                    ? "View Profile"
                                    : "Mark as Complete",
                                  ignoreBtn: [
                                    "Requested Blossom",
                                    "Requested Estriol Cream with Tretinoin",
                                  ].includes(patient.status),
                                  "selected-patient":
                                    _vm.selectedFollowupPatientInfo,
                                },
                                on: {
                                  "ignore-patient": _vm.openIgnoreModal,
                                  "update:selectedPatient": function ($event) {
                                    _vm.selectedFollowupPatientInfo = $event
                                  },
                                  "update:selected-patient": function ($event) {
                                    _vm.selectedFollowupPatientInfo = $event
                                  },
                                },
                              })
                            }),
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.currentDoctor.id
        ? _c(
            "b-col",
            {
              staticClass: "tw-block lg:tw-hidden pl-0 pr-0",
              attrs: { cols: "12", lg: "4" },
            },
            [_c("recent-view", { attrs: { lists: _vm.recentView } })],
            1
          )
        : _vm._e(),
      !_vm.currentDoctor.id
        ? _c(
            "b-col",
            {
              staticClass:
                "pl-0 pr-0 tw-transition-all duration-300 tw-absolute tw-top-0 tw-bottom-0 tw-right-0 tw-hidden lg:tw-block",
              style: {
                transform: _vm.isRecentViewOpen
                  ? "translateX(0)"
                  : "translateX(100%)",
              },
              attrs: { cols: "12", lg: "4", xl: "3" },
            },
            [
              _c("recent-view", { attrs: { lists: _vm.recentView } }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "tw-absolute tw-top-[75px] tw-left-[-55px] tw-border-none tw-bg-[#ffecca] tw-p-4 tw-pr-8 tw-rounded-lg tw-text-[#ff7a37]",
                    on: {
                      click: function ($event) {
                        _vm.isRecentViewOpen = !_vm.isRecentViewOpen
                      },
                    },
                  },
                  [
                    _vm.isRecentViewOpen
                      ? _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              fill: "none",
                              viewBox: "0 0 24 24",
                              stroke: "currentColor",
                              height: "20",
                              width: "20",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                                "stroke-width": "2",
                                d: "M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21",
                              },
                            }),
                          ]
                        )
                      : _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              fill: "none",
                              viewBox: "0 0 24 24",
                              stroke: "currentColor",
                              height: "20",
                              width: "20",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                                "stroke-width": "2",
                                d: "M15 12a3 3 0 11-6 0 3 3 0 016 0z",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                                "stroke-width": "2",
                                d: "M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z",
                              },
                            }),
                          ]
                        ),
                  ]
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            id: "clearPatient",
            "hide-header": "",
            centered: "",
            "hide-footer": "",
            size: "lg",
          },
        },
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "12", sm: "4" } }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "30",
                      height: "27",
                      viewBox: "0 0 30 27",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z",
                        fill: "#1A6A72",
                      },
                    }),
                  ]
                ),
              ]),
              _c(
                "b-col",
                { staticClass: "py-6", attrs: { cols: "12", sm: "6" } },
                [
                  _c(
                    "h1",
                    {
                      staticClass: "heading mb-4",
                      staticStyle: { "line-height": "30px" },
                    },
                    [_vm._v(" Are you sure want to ignore this message? ")]
                  ),
                  _c("div", [
                    _c("p", [
                      _vm._v(
                        " Last " +
                          _vm._s(_vm.latestmessages.length) +
                          " " +
                          _vm._s(
                            _vm.pluralize(_vm.latestmessages.length, "message")
                          ) +
                          ": "
                      ),
                    ]),
                    _c("hr"),
                    _c(
                      "div",
                      { staticClass: "tw-space-y-3" },
                      _vm._l(
                        _vm.latestmessages,
                        function ({ message, sender, fullname }, i) {
                          return _c("div", { key: i }, [
                            _c(
                              "p",
                              {
                                directives: [
                                  {
                                    name: "line-clamp",
                                    rawName: "v-line-clamp",
                                    value: 3,
                                    expression: "3",
                                  },
                                ],
                                staticClass: "mb-0",
                              },
                              [
                                sender === "PATIENT"
                                  ? _c("span", { staticClass: "text-black" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(`${fullname}: ${message}`) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                                sender === "ADMIN"
                                  ? _c("span", { staticClass: "text-black" }, [
                                      _vm._v(
                                        " " + _vm._s(`Admin: ${message}`) + " "
                                      ),
                                    ])
                                  : _c("span", { staticClass: "text-muted" }, [
                                      _vm._v(
                                        " " + _vm._s(`You: ${message}`) + " "
                                      ),
                                    ]),
                              ]
                            ),
                          ])
                        }
                      ),
                      0
                    ),
                  ]),
                  _c(
                    "b-row",
                    { staticClass: "mt-4 mb-3" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", sm: "7" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                pill: "",
                                block: "",
                                variant: "danger",
                                disabled: _vm.isProcessing,
                              },
                              on: { click: _vm.onConfirmClearMessage },
                            },
                            [
                              _vm.isProcessing
                                ? _c("b-spinner", { attrs: { small: "" } })
                                : _c("span", [_vm._v("Confirm")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", sm: "7" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                pill: "",
                                block: "",
                                variant: "outline-primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push(
                                    `/patients/${_vm.selectedPatientId}/message`
                                  )
                                },
                              },
                            },
                            [_vm._v(" Reply ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", sm: "7" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "text-primary",
                              attrs: { pill: "", block: "", variant: "link" },
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.hide("clearPatient")
                                },
                              },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "ignorePatient",
            "hide-header": "",
            centered: "",
            "hide-footer": "",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
          },
        },
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "12", sm: "5" } }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "30",
                      height: "27",
                      viewBox: "0 0 30 27",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z",
                        fill: "#1A6A72",
                      },
                    }),
                  ]
                ),
              ]),
              _c(
                "b-col",
                { staticClass: "py-6", attrs: { cols: "12", sm: "7" } },
                [
                  _c(
                    "h1",
                    {
                      staticClass: "heading mb-4",
                      staticStyle: { "line-height": "30px" },
                    },
                    [
                      _vm._v(" Hide this patient until the "),
                      _c("br", { staticClass: "d-none d-sm-inline" }),
                      _vm._v(" next event? "),
                    ]
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", sm: "7" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                pill: "",
                                block: "",
                                variant: "outline-primary",
                              },
                              on: { click: _vm.onIgnorePatient },
                            },
                            [_vm._v(" Yes ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", sm: "7" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                pill: "",
                                block: "",
                                variant: "primary",
                              },
                              on: { click: _vm.hideIgnoreModal },
                            },
                            [_vm._v(" No ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "markcomplete",
            "hide-header": "",
            "hide-header-close": "",
            centered: "",
            "hide-footer": "",
            size: "lg",
          },
        },
        [
          _c(
            "b-row",
            { staticClass: "py-5" },
            [
              _c("b-col", { attrs: { cols: "12", md: "4" } }, [
                _c("h2", { staticClass: "heading tw-text-3xl" }, [
                  _vm._v("Mark Follow-up"),
                  _c("br"),
                  _vm._v("as Complete"),
                ]),
              ]),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "7" } },
                [
                  _vm.selectedFollowupPatientInfo
                    ? _c(
                        "p",
                        {
                          staticClass: "mb-4 mr-3",
                          staticStyle: { "font-size": "16px" },
                        },
                        [
                          _vm._v(" for "),
                          _c(
                            "span",
                            {
                              staticClass: "mb-0",
                              staticStyle: {
                                "font-size": "22px",
                                "font-weight": "500",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.selectedFollowupPatientInfo.patientname
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v(" Actions Taken "),
                      ]),
                      _c("v-select", {
                        attrs: { options: _vm.actionTakenOptions },
                        model: {
                          value: _vm.actionTaken,
                          callback: function ($$v) {
                            _vm.actionTaken = $$v
                          },
                          expression: "actionTaken",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("b-form-textarea", {
                    staticClass: "p-0",
                    attrs: {
                      placeholder: "Notes (optional)",
                      rows: "6",
                      "no-resize": "",
                    },
                    model: {
                      value: _vm.followupNotesComplete,
                      callback: function ($$v) {
                        _vm.followupNotesComplete = $$v
                      },
                      expression: "followupNotesComplete",
                    },
                  }),
                  _c(
                    "b-row",
                    {
                      staticClass: "text-center mt-5",
                      attrs: { "align-h": "center" },
                    },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                pill: "",
                                block: "",
                                variant: "outline-primary",
                                disabled: _vm.isProcessing,
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.$bvModal.hide("markcomplete")
                                },
                              },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                pill: "",
                                block: "",
                                variant: "primary",
                                disabled: _vm.isProcessing,
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.onMarkComplete.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.isProcessing ? "Please wait" : "Save"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "dashboard-error",
            "hide-header": "",
            centered: "",
            "hide-footer": "",
            size: "md",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
          },
        },
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "12", sm: "5" } }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "30",
                      height: "27",
                      viewBox: "0 0 30 27",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z",
                        fill: "#1A6A72",
                      },
                    }),
                  ]
                ),
              ]),
              _c(
                "b-col",
                { staticClass: "py-6", attrs: { cols: "12", sm: "6" } },
                [
                  _c(
                    "h1",
                    {
                      staticClass: "heading mb-4",
                      staticStyle: { "line-height": "30px" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.dashboardErrorMessage === "Unauthorized request"
                              ? "This patient is no longer available."
                              : _vm.dashboardErrorMessage
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", sm: "10" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                pill: "",
                                block: "",
                                variant: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.hide("dashboard-error")
                                },
                              },
                            },
                            [_vm._v(" OK ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }