var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card-dashboard" }, [
    _c("div", { staticClass: "card-counter" }, [
      _c(
        "div",
        {
          staticClass:
            "tw-py-3 tw-text-primary tw-capitalize tw-border tw-border-solid tw-border-primary tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-flex tw-justify-between tw-relative",
        },
        [
          _c("span", [
            _vm._v(" " + _vm._s(_vm.title) + " "),
            _vm.text
              ? _c("small", { staticClass: "text-muted" }, [
                  _vm._v(_vm._s(`(${_vm.text})`)),
                ])
              : _vm._e(),
          ]),
          _vm.dropdown
            ? [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "click-outside",
                        rawName: "v-click-outside",
                        value: _vm.onClickOutside,
                        expression: "onClickOutside",
                      },
                    ],
                  },
                  [
                    _c("b-icon", {
                      staticClass: "tw-cursor-pointer",
                      attrs: { icon: "caret-down-fill" },
                      on: {
                        click: function ($event) {
                          _vm.show = !_vm.show
                        },
                      },
                    }),
                    _vm.show
                      ? _c(
                          "ul",
                          {
                            staticClass:
                              "card-dropdown list-unstyled tw-absolute tw-left-0 tw-right-0 tw-top-auto tw-mb-0 tw-mt-[13px] tw-shadow-md tw-z-20",
                          },
                          _vm._l(_vm.options, function (filter, i) {
                            return _c("li", { key: i }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selected,
                                    expression: "selected",
                                  },
                                ],
                                attrs: {
                                  id: "filter" + i,
                                  type: "radio",
                                  name: "filter",
                                },
                                domProps: {
                                  value: filter.value,
                                  checked: _vm._q(_vm.selected, filter.value),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      _vm.selected = filter.value
                                    },
                                    function ($event) {
                                      return _vm.onChange(filter)
                                    },
                                  ],
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "tw-w-full tw-py-4 tw-px-3 tw-mb-0 tw-select-none",
                                  attrs: {
                                    oncontextmenu: "return false;",
                                    for: "filter" + i,
                                  },
                                },
                                [_vm._v(" " + _vm._s(filter.text) + " ")]
                              ),
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass:
            "display-2 mb-0 heading tw-text-primary tw-text-[44px] tw-leading-[40px] tw-tracking-[0.01em] tw-py-8 tw-px-2",
        },
        [
          _vm.isBusy
            ? _c("b-skeleton", { attrs: { width: "60px", height: "65px" } })
            : _c("span", { staticClass: "mb-0" }, [
                _vm._v(_vm._s(_vm.counter)),
              ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }