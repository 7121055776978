var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onClickOutside,
          expression: "onClickOutside",
        },
      ],
      staticClass:
        "tw-relative tw-rounded-md tw-pt-2 tw-mb-4 tw-shadow-none tw-transition-all hover:tw-shadow-lg",
      class: [
        _vm.snoozed
          ? [
              "Requested Blossom",
              "Requested Estriol Cream with Tretinoin",
            ].includes(_vm.patientInfo.status)
            ? "tw-bg-[#a783ff]"
            : _vm.patientInfo.status === "Transferred Patient"
            ? "tw-bg-[#ff8d40]"
            : "tw-bg-muted"
          : _vm.patientInfo.action === "Verify ID"
          ? "tw-bg-muted"
          : _vm.bgColor,
        _vm.isClicked && !_vm.viewOnly ? "card-dashboard-item-focus" : "",
        _vm.viewOnly ? "card-dashboard-darkmode" : "card-dashboard-item",
      ],
      on: {
        click: function ($event) {
          _vm.isClicked = !_vm.isClicked
        },
      },
    },
    [
      _vm.snoozed
        ? _c(
            "span",
            {
              staticClass:
                "tw-block tw-w-full tw-text-center tw-px-4 tw-pb-1 tw--mt-1 tw-text-[10px] tw-text-white",
              class: [
                "Requested Blossom",
                "Requested Estriol Cream with Tretinoin",
              ].includes(_vm.patientInfo.status)
                ? "tw-bg-[#a783ff]"
                : _vm.patientInfo.status === "Transferred Patient"
                ? "tw-bg-[#ff8d40]"
                : "tw-bg-muted",
            },
            [_vm._v(" " + _vm._s(_vm.snoozedText) + " ")]
          )
        : _vm._e(),
      _vm.patientInfo.status === "New Admin Message"
        ? _c(
            "div",
            { staticClass: "tw-text-center tw-bg-white tw-pt-1 from-admin" },
            [_vm._v(" From Admin ")]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "card-dashboard-content tw-grid tw-grid-cols-2 tw-px-2 tw-pt-3",
          class: _vm.viewOnly ? "tw-rounded-b-md" : "tw-rounded-b-sm",
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.name))]),
          _c("span", { staticClass: "tw-text-right" }, [
            _vm._v(_vm._s(_vm.timeStamp)),
          ]),
          _c(
            "span",
            {
              staticClass:
                "card-location tw-mt-1 tw-text-[#4F4F4F] tw-text-[12px]",
            },
            [
              _vm._v(
                " " + _vm._s(_vm.date) + " | " + _vm._s(_vm.location) + " "
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "tw-col-span-2 tw-mt-3" },
            [_vm._t("message")],
            2
          ),
          _vm.disabled
            ? _c(
                "div",
                {
                  staticClass:
                    "tw-col-span-2 tw-text-[10px] tw-pb-1 tw-opacity-50 tw-text-center",
                },
                [_vm._v(" " + _vm._s(_vm.disabledText) + " ")]
              )
            : _vm._e(),
        ]
      ),
      !_vm.viewOnly
        ? _c(
            "div",
            {
              staticClass:
                "btn-holder tw-flex tw-h-[40px] tw-w-full tw-rounded-b-md tw-transition-all",
            },
            [
              _vm.patientInfo.status === "Follow-Up"
                ? _c(
                    "button",
                    {
                      staticClass:
                        "snooze tw-border-0 tw-text-center tw-w-full tw-h-[40px]",
                      on: {
                        click: function ($event) {
                          return _vm.$router.push(
                            `/patients/${_vm.patientInfo.id}/intake`
                          )
                        },
                      },
                    },
                    [_vm._v(" View Intake History ")]
                  )
                : _vm._e(),
              _vm.clearBtn
                ? _c(
                    "button",
                    {
                      staticClass:
                        "clear tw-border-0 tw-text-center tw-w-full tw-h-[40px]",
                      on: { click: _vm.onClearMessage },
                    },
                    [_vm._v(" Clear ")]
                  )
                : _vm._e(),
              _vm.ignoreBtn
                ? _c(
                    "button",
                    {
                      staticClass:
                        "clear tw-border-0 tw-text-center tw-w-full tw-h-[40px]",
                      on: { click: _vm.onIgnore },
                    },
                    [_vm._v(" Ignore ")]
                  )
                : _vm._e(),
              _vm.snoozeBtn && !_vm.snoozed
                ? _c(
                    "button",
                    {
                      staticClass:
                        "snooze tw-border-0 tw-text-center tw-w-full tw-h-[40px]",
                      on: { click: _vm.onSnoozeMessage },
                    },
                    [_vm._v(" Snooze ")]
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass:
                    "tw-border-0 tw-text-center tw-w-full tw-h-[40px]",
                  class:
                    _vm.patientInfo.status === "New Admin Message"
                      ? "reply"
                      : _vm.bgColor,
                  attrs: { disabled: _vm.disabled },
                  on: { click: _vm.onActionClick },
                },
                [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
              ),
              ["Pending Message", "Transferred"].includes(
                _vm.patientInfo.action
              )
                ? _c(
                    "button",
                    {
                      staticClass:
                        "view-profile tw-border-0 tw-text-center tw-w-full tw-h-[40px]",
                      on: {
                        click: function ($event) {
                          return _vm.$router.push(
                            `/patients/profile/${_vm.patientInfo.id}`
                          )
                        },
                      },
                    },
                    [_vm._v(" View Profile ")]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }