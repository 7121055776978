var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "recent-view tw-relative" },
    [
      _c(
        "b-card",
        {
          staticStyle: {
            "background-color": "transparent",
            border: "0",
            "margin-bottom": "0",
          },
          attrs: { "no-body": "" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "h4",
                    {
                      staticClass: "mb-0 bold",
                      staticStyle: { "letter-spacing": "1px" },
                    },
                    [_vm._v(" Recently viewed patients ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "b-list-group",
            _vm._l(_vm.lists, function (list, i) {
              return _c(
                "b-list-group-item",
                { key: i },
                [
                  _c(
                    "b-row",
                    { attrs: { "align-v": "center" } },
                    [
                      _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "auto" } },
                        [
                          _c("b-avatar", {
                            attrs: { variant: "primary", src: list.img },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "pr-0" },
                        [
                          _c("h4", { staticClass: "mt-0 mb-0" }, [
                            _vm._v(_vm._s(list.name)),
                          ]),
                          _c(
                            "router-link",
                            {
                              attrs: { to: `/patients/profile/${list.id}` },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.viewProfile(list.id)
                                },
                              },
                            },
                            [_c("small", [_vm._v("View Profile")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }