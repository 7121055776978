<template>
  <b-row
    v-cloak
    style="background-color: rgba(26, 106, 114, 0.02)"
    class="tw-relative xl:tw-pr-5"
  >
    <b-col
      cols="12"
      :lg="isRecentViewOpen ? '8' : '12'"
      :xl="isRecentViewOpen ? '9' : '12'"
      class="tw-transition-all"
    >
      <b-row>
        <b-col
          class="tw-py-6"
          cols="12"
          v-if="transferredPatientList.length > 0"
        >
          <div
            class="
              tw-flex
              tw-justify-between
              tw-bg-[#ffecca]
              tw-text-[#ff7a37]
              tw-px-3
              tw-py-2
            "
          >
            <p class="tw-mb-0">
              You have {{ transferredPatientList.length }} transferred patients
              ready to be prescribed
            </p>
          </div>
        </b-col>
        <b-col cols="12">
          <ul
            class="
              list-unstyled
              xl:tw-grid-cols-3
              tw-space-x-4 tw-hidden
              xl:tw-grid
            "
          >
            <li>
              <CardDashboard
                title="intake"
                :counter="dashboardData.newpatient || 0"
                :is-busy="!hasDashboardData"
              />
              <div class="card-dashboard-list">
                <!-- <CardDashboardItem
                  bg-color="unread"
                  name="Fletch Skinner"
                  time-stamp="a day ago"
                  :location="`${patient.state}`"
                  date="07/29/1960"
                  disabled
                  disabledText="You can not accept this patient at this time."
                /> -->
                <CardDashboardItem
                  v-for="patient in transferredPatientList"
                  :key="patient.id"
                  bg-color="migrate"
                  :name="patient.name"
                  :time-stamp="patient.distanceToNow"
                  :location="`${patient.state}`"
                  :date="patient.birthday"
                  :patient-info="patient"
                  button-text="Prescribe"
                  :snoozed="true"
                  :snoozed-text="`Transferred from ${patient.transferredfrom}`"
                  @intake-error="onIntakeError"
                />
                <CardDashboardItem
                  v-for="patient in newPatientsList"
                  :key="patient.id"
                  :bg-color="
                    patient.action === 'Pending Message' ? 'message' : 'unread'
                  "
                  :name="patient.name"
                  :time-stamp="patient.distanceToNow"
                  :location="`${patient.state}`"
                  :date="patient.birthday"
                  :patient-info="patient"
                  :button-text="
                    patient.action === 'Verify ID'
                      ? 'Verify'
                      : patient.action === 'Pending Message'
                      ? 'Message'
                      : 'Accept'
                  "
                  :snoozed="['Pending Message'].includes(patient.action)"
                  :snoozed-text="
                    patient.action === 'Pending Message' ? 'Pending Action' : ''
                  "
                  @intake-error="onIntakeError"
                />
              </div>
            </li>
            <li>
              <CardDashboard
                title="messages"
                :counter="newMessageList.length"
                :is-busy="!hasDashboardData"
              />
              <div class="card-dashboard-list">
                <CardDashboardItem
                  v-for="patient in newMessageList"
                  :key="
                    [
                      'Requested Blossom',
                      'Requested Estriol Cream with Tretinoin',
                    ].includes(patient.status)
                      ? `prescription-${patient.id}`
                      : patient.id
                  "
                  :bg-color="
                    [
                      'Requested Blossom',
                      'Requested Estriol Cream with Tretinoin',
                    ].includes(patient.status)
                      ? 'prescribe'
                      : patient.status === 'New Admin Message'
                      ? 'admin-message'
                      : 'unread'
                  "
                  :name="patient.name"
                  :time-stamp="patient.distanceToNow"
                  :location="`${patient.state}`"
                  :date="patient.birthday"
                  :patient-info="patient"
                  :clearBtn="true"
                  :snoozeBtn="
                    [
                      'Requested Blossom',
                      'Requested Estriol Cream with Tretinoin',
                    ].includes(patient.status)
                      ? false
                      : true
                  "
                  :button-text="
                    [
                      'Requested Blossom',
                      'Requested Estriol Cream with Tretinoin',
                    ].includes(patient.status)
                      ? 'View Profile'
                      : 'Reply'
                  "
                  @clear-message="onClearMessage"
                  @snooze-message="onSnoozeMessage"
                  @ignore-patient="openIgnoreModal"
                  :snoozed="
                    [
                      'Snoozed',
                      'Requested Blossom',
                      'Requested Estriol Cream with Tretinoin',
                    ].includes(patient.status)
                      ? true
                      : false
                  "
                  :snoozed-text="snoozedText(patient)"
                >
                  <template #message>
                    <div
                      class="mb-3"
                      v-if="
                        ![
                          'Requested Blossom',
                          'Requested Estriol Cream with Tretinoin',
                        ].includes(patient.status)
                      "
                    >
                      <div
                        v-for="(
                          { message, sender }, i
                        ) in patient.latestmessages.slice(0, 2).reverse()"
                        :key="i"
                      >
                        <p class="mb-0 tw-text-[12px]" v-line-clamp="2">
                          <span v-if="sender === 'PATIENT'" class="text-black">
                            {{ `${patient.name}: ${message}` }}
                          </span>
                          <span
                            v-else-if="sender === 'ADMIN'"
                            class="text-black"
                          >
                            {{ `Admin: ${message}` }}
                          </span>
                          <span v-else class="text-muted">
                            {{ `You: ${message}` }}
                          </span>
                        </p>
                      </div>
                    </div>
                  </template>
                </CardDashboardItem>
                <!-- TODO: Snoozed items -->
                <!-- <CardDashboardItem
                  v-for="(n, i) in messageCard"
                  :key="i"
                  :bg-color="n.color"
                  :name="n.name"
                  :time-stamp="n.timeStamp"
                  :location="n.location"
                  :date="n.date"
                  clearBtn
                  :snoozeBtn="n.snoozeBtn"
                  button-text="Reply"
                  :snoozed="n.snoozed"
                  :snoozed-text="n.snoozedText"
                >
                  <template #message>
                    <div v-for="(msg, i) in n.message" :key="i">
                      <p>{{ msg.user }}</p>
                    </div>
                  </template>
                </CardDashboardItem> -->
              </div>
            </li>
            <li>
              <CardDashboard
                title="follow up"
                :counter="followUpList.length"
                :is-busy="!hasDashboardData"
              />
              <div class="card-dashboard-list">
                <CardDashboardItem
                  v-for="patient in followUpList"
                  :key="
                    [
                      'Requested Blossom',
                      'Requested Estriol Cream with Tretinoin',
                    ].includes(patient.status)
                      ? `prescription-${patient.id}`
                      : `followup-${patient.id}`
                  "
                  :bg-color="
                    [
                      'Requested Blossom',
                      'Requested Estriol Cream with Tretinoin',
                    ].includes(patient.status)
                      ? 'prescribe'
                      : 'unread'
                  "
                  :name="patient.name"
                  :time-stamp="patient.distanceToNow"
                  :location="`${patient.state}`"
                  :date="patient.birthday"
                  :patient-info="patient"
                  :button-text="
                    [
                      'Requested Blossom',
                      'Requested Estriol Cream with Tretinoin',
                    ].includes(patient.status)
                      ? 'View Profile'
                      : 'Mark as Complete'
                  "
                  :ignoreBtn="
                    [
                      'Requested Blossom',
                      'Requested Estriol Cream with Tretinoin',
                    ].includes(patient.status)
                  "
                  @ignore-patient="openIgnoreModal"
                  :selected-patient.sync="selectedFollowupPatientInfo"
                />
              </div>
            </li>
          </ul>

          <div class="tw-block xl:tw-hidden">
            <ul class="list-unstyled tw-grid tw-grid-cols-3">
              <li
                v-for="tab in tabList"
                :key="tab.id"
                :class="
                  activeTab == tab.id
                    ? 'dashboard-tab-active tw-border tw-border-solid tw-border-t-0 tw-border-l-0 tw-border-r-0'
                    : 'tw-opacity-25'
                "
                class="tw-py-3 tw-capitalize"
                @click="activeTab = tab.id"
              >
                {{ tab.text }}
              </li>
            </ul>

            <template v-if="activeTab == 'intake'">
              <div
                class="
                  heading
                  tw-text-[44px]
                  tw-leading-[40px]
                  tw-tracking-[0.01em]
                  tw-py-8
                  tw-px-2
                "
              >
                {{ dashboardData.newpatient }}
              </div>

              <div class="card-dashboard-list">
                <!-- <CardDashboardItem
                  bg-color="unread"
                  name="Fletch Skinner"
                  time-stamp="a day ago"
                  :location="`${patient.state}`"
                  date="07/29/1960"
                  disabled
                  disabledText="You can not accept this patient at this time."
                /> -->
                <CardDashboardItem
                  v-for="patient in transferredPatientList"
                  :key="patient.id"
                  bg-color="migrate"
                  :name="patient.name"
                  :time-stamp="patient.distanceToNow"
                  :location="`${patient.state}`"
                  :date="patient.birthday"
                  :patient-info="patient"
                  button-text="Prescribe"
                  :snoozed="true"
                  :snoozed-text="`Transferred from ${patient.transferredfrom}`"
                  @intake-error="onIntakeError"
                />
                <CardDashboardItem
                  v-for="patient in newPatientsList"
                  :key="patient.id"
                  :bg-color="
                    patient.action === 'Pending Message' ? 'message' : 'unread'
                  "
                  :name="patient.name"
                  :time-stamp="patient.distanceToNow"
                  :location="`${patient.state}`"
                  :date="patient.birthday"
                  :patient-info="patient"
                  :button-text="
                    patient.action === 'Verify ID'
                      ? 'Verify'
                      : patient.action === 'Pending Message'
                      ? 'Message'
                      : 'Accept'
                  "
                  :snoozed="['Pending Message'].includes(patient.action)"
                  :snoozed-text="
                    patient.action === 'Pending Message' ? 'Pending Action' : ''
                  "
                  @intake-error="onIntakeError"
                />
              </div>
            </template>

            <template v-if="activeTab == 'messages'">
              <div
                class="
                  heading
                  tw-text-[44px]
                  tw-leading-[40px]
                  tw-tracking-[0.01em]
                  tw-py-8
                  tw-px-2
                "
              >
                {{ newMessageList.length }}
              </div>

              <div class="card-dashboard-list">
                <CardDashboardItem
                  v-for="patient in newMessageList"
                  :key="
                    [
                      'Requested Blossom',
                      'Requested Estriol Cream with Tretinoin',
                    ].includes(patient.status)
                      ? `prescription-${patient.id}`
                      : patient.id
                  "
                  :bg-color="
                    [
                      'Requested Blossom',
                      'Requested Estriol Cream with Tretinoin',
                    ].includes(patient.status)
                      ? 'prescribe'
                      : patient.status === 'New Admin Message'
                      ? 'admin-message'
                      : 'unread'
                  "
                  :name="patient.name"
                  :time-stamp="patient.distanceToNow"
                  :location="`${patient.state}`"
                  :date="patient.birthday"
                  :patient-info="patient"
                  :clearBtn="true"
                  :snoozeBtn="
                    [
                      'Requested Blossom',
                      'Requested Estriol Cream with Tretinoin',
                    ].includes(patient.status)
                      ? false
                      : true
                  "
                  :button-text="
                    [
                      'Requested Blossom',
                      'Requested Estriol Cream with Tretinoin',
                    ].includes(patient.status)
                      ? 'View Profile'
                      : 'Reply'
                  "
                  @clear-message="onClearMessage"
                  @snooze-message="onSnoozeMessage"
                  @ignore-patient="openIgnoreModal"
                  :snoozed="
                    [
                      'Snoozed',
                      'Requested Blossom',
                      'Requested Estriol Cream with Tretinoin',
                    ].includes(patient.status)
                      ? true
                      : false
                  "
                  :snoozed-text="snoozedText(patient)"
                >
                  <template #message>
                    <div
                      class="mb-3"
                      v-if="
                        [
                          'Requested Blossom',
                          'Requested Estriol Cream with Tretinoin',
                        ].includes(patient.status)
                      "
                    >
                      <div
                        v-for="(
                          { message, sender }, i
                        ) in patient.latestmessages.slice(0, 2).reverse()"
                        :key="i"
                      >
                        <p class="mb-0 tw-text-[12px]" v-line-clamp="2">
                          <span v-if="sender === 'PATIENT'" class="text-black">
                            {{ `${patient.name}: ${message}` }}
                          </span>
                          <span
                            v-else-if="sender === 'ADMIN'"
                            class="text-black"
                          >
                            {{ `Admin: ${message}` }}
                          </span>
                          <span v-else class="text-muted">
                            {{ `You: ${message}` }}
                          </span>
                        </p>
                      </div>
                    </div>
                  </template>
                </CardDashboardItem>
                <!-- TODO: Snoozed items -->
                <!-- <CardDashboardItem
                  v-for="(n, i) in messageCard"
                  :key="i"
                  :bg-color="n.color"
                  :name="n.name"
                  :time-stamp="n.timeStamp"
                  :location="n.location"
                  :date="n.date"
                  clearBtn
                  :snoozeBtn="n.snoozeBtn"
                  button-text="Reply"
                  
                  
                >
                  <template #message>
                    <div v-for="(msg, i) in n.message" :key="i">
                      <p>{{ msg.user }}</p>
                    </div>
                  </template>
                </CardDashboardItem> -->
              </div>
            </template>

            <template v-if="activeTab == 'followup'">
              <div
                class="
                  heading
                  tw-text-[44px]
                  tw-leading-[40px]
                  tw-tracking-[0.01em]
                  tw-py-8
                  tw-px-2
                "
              >
                {{ followUpList.length }}
              </div>
              <div class="card-dashboard-list">
                <CardDashboardItem
                  v-for="patient in followUpList"
                  :key="
                    [
                      'Requested Blossom',
                      'Requested Estriol Cream with Tretinoin',
                    ].includes(patient.status)
                      ? `prescription-${patient.id}`
                      : `followup-${patient.id}`
                  "
                  :bg-color="
                    [
                      'Requested Blossom',
                      'Requested Estriol Cream with Tretinoin',
                    ].includes(patient.status)
                      ? 'prescribe'
                      : 'unread'
                  "
                  :name="patient.name"
                  :time-stamp="patient.distanceToNow"
                  :location="`${patient.state}`"
                  :date="patient.birthday"
                  :patient-info="patient"
                  :button-text="
                    [
                      'Requested Blossom',
                      'Requested Estriol Cream with Tretinoin',
                    ].includes(patient.status)
                      ? 'View Profile'
                      : 'Mark as Complete'
                  "
                  :ignoreBtn="
                    [
                      'Requested Blossom',
                      'Requested Estriol Cream with Tretinoin',
                    ].includes(patient.status)
                  "
                  @ignore-patient="openIgnoreModal"
                  :selected-patient.sync="selectedFollowupPatientInfo"
                />
              </div>
            </template>
          </div>
        </b-col>
      </b-row>
    </b-col>
    <b-col
      cols="12"
      lg="4"
      class="tw-block lg:tw-hidden pl-0 pr-0"
      v-if="!currentDoctor.id"
    >
      <recent-view :lists="recentView" />
    </b-col>
    <b-col
      v-if="!currentDoctor.id"
      cols="12"
      lg="4"
      xl="3"
      class="
        pl-0
        pr-0
        tw-transition-all
        duration-300
        tw-absolute tw-top-0 tw-bottom-0 tw-right-0 tw-hidden
        lg:tw-block
      "
      :style="{
        transform: isRecentViewOpen ? 'translateX(0)' : 'translateX(100%)',
      }"
    >
      <recent-view :lists="recentView">
        <button
          class="
            tw-absolute
            tw-top-[75px]
            tw-left-[-55px]
            tw-border-none
            tw-bg-[#ffecca]
            tw-p-4
            tw-pr-8
            tw-rounded-lg
            tw-text-[#ff7a37]
          "
          @click="isRecentViewOpen = !isRecentViewOpen"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            height="20"
            width="20"
            v-if="isRecentViewOpen"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            height="20"
            width="20"
            v-else
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
            />
          </svg>
        </button>
      </recent-view>
    </b-col>

    <b-modal id="clearPatient" hide-header centered hide-footer size="lg">
      <b-row>
        <b-col cols="12" sm="4">
          <svg
            width="30"
            height="27"
            viewBox="0 0 30 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z"
              fill="#1A6A72"
            />
          </svg>
        </b-col>
        <b-col cols="12" sm="6" class="py-6">
          <h1 class="heading mb-4" style="line-height: 30px">
            Are you sure want to ignore this message?
          </h1>
          <div>
            <p>
              Last {{ latestmessages.length }}
              {{ pluralize(latestmessages.length, "message") }}:
            </p>
            <hr />
            <div class="tw-space-y-3">
              <div
                v-for="({ message, sender, fullname }, i) in latestmessages"
                :key="i"
              >
                <p class="mb-0" v-line-clamp="3">
                  <span v-if="sender === 'PATIENT'" class="text-black">
                    {{ `${fullname}: ${message}` }}
                  </span>
                  <span v-if="sender === 'ADMIN'" class="text-black">
                    {{ `Admin: ${message}` }}
                  </span>
                  <span v-else class="text-muted">
                    {{ `You: ${message}` }}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <b-row class="mt-4 mb-3">
            <b-col cols="12" sm="7">
              <b-button
                pill
                block
                variant="danger"
                :disabled="isProcessing"
                @click="onConfirmClearMessage"
              >
                <b-spinner v-if="isProcessing" small></b-spinner>
                <span v-else>Confirm</span>
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col cols="12" sm="7">
              <b-button
                pill
                block
                variant="outline-primary"
                @click="$router.push(`/patients/${selectedPatientId}/message`)"
              >
                Reply
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" sm="7">
              <b-button
                pill
                block
                @click="$bvModal.hide('clearPatient')"
                variant="link"
                class="text-primary"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="ignorePatient"
      hide-header
      centered
      hide-footer
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-row>
        <b-col cols="12" sm="5">
          <svg
            width="30"
            height="27"
            viewBox="0 0 30 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z"
              fill="#1A6A72"
            />
          </svg>
        </b-col>
        <b-col cols="12" sm="7" class="py-6">
          <h1 class="heading mb-4" style="line-height: 30px">
            Hide this patient until the <br class="d-none d-sm-inline" />
            next event?
          </h1>
          <b-row class="mb-3">
            <b-col cols="12" sm="7">
              <b-button
                pill
                block
                @click="onIgnorePatient"
                variant="outline-primary"
              >
                Yes
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" sm="7">
              <b-button pill block @click="hideIgnoreModal" variant="primary">
                No
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="markcomplete"
      hide-header
      hide-header-close
      centered
      hide-footer
      size="lg"
    >
      <b-row class="py-5">
        <b-col cols="12" md="4">
          <h2 class="heading tw-text-3xl">Mark Follow-up<br />as Complete</h2>
        </b-col>
        <b-col cols="12" md="7">
          <p
            class="mb-4 mr-3"
            style="font-size: 16px"
            v-if="selectedFollowupPatientInfo"
          >
            for
            <span class="mb-0" style="font-size: 22px; font-weight: 500">
              {{ selectedFollowupPatientInfo.patientname }}
            </span>
          </p>
          <div class="mb-4">
            <small class="text-muted"> Actions Taken </small>
            <v-select
              :options="actionTakenOptions"
              v-model="actionTaken"
            ></v-select>
          </div>
          <b-form-textarea
            placeholder="Notes (optional)"
            v-model="followupNotesComplete"
            rows="6"
            no-resize
            class="p-0"
          >
          </b-form-textarea>
          <b-row class="text-center mt-5" align-h="center">
            <b-col cols="6">
              <b-button
                pill
                block
                variant="outline-primary"
                :disabled="isProcessing"
                @click.prevent="$bvModal.hide('markcomplete')"
              >
                Cancel
              </b-button>
            </b-col>
            <b-col cols="6">
              <b-button
                pill
                block
                variant="primary"
                :disabled="isProcessing"
                @click.prevent="onMarkComplete"
              >
                {{ isProcessing ? "Please wait" : "Save" }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="dashboard-error"
      hide-header
      centered
      hide-footer
      size="md"
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-row>
        <b-col cols="12" sm="5">
          <svg
            width="30"
            height="27"
            viewBox="0 0 30 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z"
              fill="#1A6A72"
            />
          </svg>
        </b-col>
        <b-col cols="12" sm="6" class="py-6">
          <h1 class="heading mb-4" style="line-height: 30px">
            {{
              dashboardErrorMessage === "Unauthorized request"
                ? "This patient is no longer available."
                : dashboardErrorMessage
            }}
          </h1>
          <b-row class="mb-3">
            <b-col cols="12" sm="10">
              <b-button
                pill
                block
                variant="primary"
                @click="$bvModal.hide('dashboard-error')"
              >
                OK
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import { DashboardService } from "@/services/dashboard.service.js";
import { format, formatDistanceToNow, fromUnixTime } from "date-fns";
import _ from "lodash";

// import dashboardCounter from "@/components/partials/Counter";
import recentView from "@/components/partials/RecentView";
import CardDashboard from "@/components/partials/CardDashboard";
import CardDashboardItem from "@/components/partials/CardDashboardItem";
const hours = new Date().getHours();
export default {
  name: "dashboard",
  components: {
    recentView,
    CardDashboard,
    CardDashboardItem,
  },
  data() {
    return {
      currentUser: "",
      dashboard: {},
      isBusy: true,
      recentView: [],
      activeTab: "intake",

      tabList: [
        {
          id: "intake",
          text: "intake",
        },
        {
          id: "messages",
          text: "messages",
        },
        {
          id: "followup",
          text: "follow Up",
        },
      ],

      fields: [
        { key: "date", sortable: true },
        { key: "patientInfo", label: "Name", sortable: true },
        { key: "treatment", sortable: false },
        { key: "status", sortable: true },
        // { key: "messagecount", sortable: true, tdClass: "d-none" },
        { key: "action", label: "action", sortable: false },
      ],

      followupcounter: 0,
      // for message dropdown
      messageOption: [
        { value: "unread", text: "Unread" },
        { value: "all", text: "All" },
        { value: "snoozed", text: "Snoozed" },
      ],
      // for prescription dropdown
      prescriptionOption: [
        { value: "all", text: "All" },
        { value: "followUp", text: "Follow Up" },
        { value: "request", text: "Prescription Requests" },
      ],

      selectedConversationId: "",
      selectedPatientId: "",
      isProcessing: false,
      latestmessages: [],
      isRecentViewOpen: false,

      intervalFunction: null,
      selectedIgnorePatientInfo: null,

      actionTakenOptions: [],
      actionTaken: "",
      followupNotesComplete: "",
      selectedFollowupPatientInfo: null,

      dashboardErrorMessage: "",
    };
  },
  computed: {
    greetings() {
      if (hours >= 12 && hours < 18) return "☀️ Good Afternoon";
      if (hours >= 18) return "🌙 Good Evening";
      return "🌅 Good Morning";
    },
    items() {
      if (!_.isEmpty(this.dashboardData)) {
        const dashboardData = this.dashboardData.patientaction.map(
          this.formatDashboardItems
        );

        return _.orderBy(
          dashboardData,
          ["oldestMessageSentAt", "date"],
          ["asc"]
        );
      } else {
        return [];
      }
    },
    newPatientsList() {
      return this.items.filter((patient) =>
        ["New Patient"].includes(patient.status)
      );
    },
    newMessageList() {
      let messagesList;
      if (this.isDashboardEdge) {
        const dashboardMessages =
          this.$store.getters["dashboard/getDashboardMessages"];
        messagesList = dashboardMessages.map(this.formatDashboardItems);
      } else {
        messagesList = this.items.filter((patient) => {
          return [
            "New Message",
            "Snoozed",
            "Requested Blossom",
            "Requested Estriol Cream with Tretinoin",
            "New Admin Message",
          ].includes(patient.status);
        });
      }
      return _.orderBy(messagesList, ["status"], ["asc", "desc"]);
    },
    followUpList() {
      return this.items.filter((patient) => {
        return ["Follow-Up"].includes(patient.status);
      });
    },
    transferredPatientList() {
      return this.items.filter((patient) => {
        return ["Transferred Patient"].includes(patient.status);
      });
    },
    hasDashboardData() {
      return !_.isEmpty(this.dashboardData) || !this.isBusy;
    },

    currentDoctor() {
      return this.$store.state.globals.currentDoctor;
    },
    ...mapGetters({
      dashboardData: "dashboard/getDashboardData",
      patients: "dashboard/getPatients",
    }),
    isDashboardEdge() {
      return this.$route.query.nextDashboardSwimlane === "true";
    },
  },
  watch: {
    dashboardData(val) {
      this.followupcounter = val.followup;
    },
  },
  async mounted() {
    window.analytics.page();

    const user = await this.$store.dispatch("user/getUser");
    this.currentUser = `${user.firstname} ${user.lastname}`;
    this.$token = localStorage.getItem("accessToken");

    if (user?.canacceptpatient) {
      this.intervalFunction = setInterval(async () => {
        Promise.all([
          this.$store.dispatch("dashboard/getDashboardData"),
          this.fetchDashboardDataEdge(),
        ]);
      }, 120000);
      Promise.all([
        this.$store.dispatch("dashboard/getDashboardData"),
        this.fetchDashboardDataEdge(),
      ]);
    } else {
      this.$store.dispatch("dashboard/resetDashboardData");
    }

    this.isBusy = false;
    let recentlyViewedPatients = JSON.parse(
      localStorage.getItem("recentlyViewedPatients")
    );
    if (recentlyViewedPatients) {
      for (let i = 0; i < recentlyViewedPatients.length; i++) {
        let patient = recentlyViewedPatients[i];
        this.recentView.push({
          name: `${patient.firstname} ${patient.lastname}`,
          img: patient.profileUrl,
          id: patient.id,
          birthday: patient.birthday,
          state: patient.state,
        });
      }

      this.recentView.reverse();
    }

    DashboardService.getLookup("followupaction").then(({ data }) => {
      this.actionTakenOptions = data.map((reason) => ({
        label: reason.name,
        code: reason.id,
      }));

      if (this.actionTakenOptions.length > 0) {
        this.actionTaken = this.actionTakenOptions[0];
      }
    });
  },
  destroyed() {
    clearInterval(this.intervalFunction);
  },
  methods: {
    formatDashboardItems(data) {
      return {
        ...data,
        patientInfo: {
          fullName: data.name,
          birthday: data.birthday,
          state: data.state,
        },
        distanceToNow: data.date
          ? formatDistanceToNow(fromUnixTime(data.date), {
              addSuffix: true,
            })
          : "",
        birthday: data.birthday
          ? format(new Date(data.birthday), "MM/dd/yyyy")
          : "",
      };
    },
    removeSingleRow({ patientid, date }) {
      this.followupcounter -= 1;
      this.$bvModal.hide("ignorePatient");
      this.dashboardData.patientaction.splice(
        this.dashboardData.patientaction.findIndex(
          (item) => item.id === patientid && item.date === date
        ),
        1
      );
    },
    fetchDashboardDataEdge() {
      if (this.isDashboardEdge) {
        this.$store.dispatch("dashboard/getDashboardMessages");
      }
    },
    onClearMessage({ conversationid, latestmessages, patientid }) {
      this.$bvModal.show("clearPatient");
      this.selectedConversationId = conversationid;
      this.selectedPatientId = patientid;
      this.latestmessages = latestmessages.reverse();
    },
    async onConfirmClearMessage() {
      this.isProcessing = true;
      await this.$store.dispatch("message/ignoreMessage", {
        conversationid: this.selectedConversationId,
      });
      this.fetchDashboardDataEdge();
      this.$bvModal.hide("clearPatient");
      this.isProcessing = false;
    },
    async onSnoozeMessage({ conversationid }) {
      await this.$store.dispatch("message/snoozeMessage", {
        conversationid,
      });
      this.fetchDashboardDataEdge();
    },
    onIgnorePatient() {
      if (this.selectedIgnorePatientInfo.action === "RX Request, Ignore") {
        this.$store
          .dispatch("upsell/denyUpsell", {
            upsellid: this.selectedIgnorePatientInfo.patientupsellid,
          })
          .then(() => {
            this.$bvModal.hide("ignorePatient");
          });
      } else if (
        this.selectedIgnorePatientInfo.action === "RX Refill, Ignore"
      ) {
        let ignorePrescriptionPromises =
          this.selectedIgnorePatientInfo.prescriptionorderid.map((id) => {
            return this.$store.dispatch("prescription/ignorePrescription", {
              patientid: this.selectedIgnorePatientInfo.id,
              patientfollowupid: id,
            });
          });
        Promise.all(ignorePrescriptionPromises)
          .then((data) => {
            this.removeSingleRow({
              patientid: this.selectedIgnorePatientInfo.id,
              date: this.selectedIgnorePatientInfo.date,
            });
          })
          .catch((e) => console.error(e));
      } else {
        let ignoreFollowupPromises =
          this.selectedIgnorePatientInfo.patientfollowupid.map((id) => {
            return this.$store.dispatch("prescription/ignoreFollowup", {
              patientid: this.selectedIgnorePatientInfo.id,
              patientfollowupid: id,
            });
          });
        Promise.all(ignoreFollowupPromises)
          .then((data) => {
            this.removeSingleRow({
              patientid: this.selectedIgnorePatientInfo.id,
              date: this.selectedIgnorePatientInfo.date,
            });
          })
          .catch((e) => console.error(e));
      }
    },
    openIgnoreModal({ patientInfo }) {
      this.$bvModal.show("ignorePatient");
      this.selectedIgnorePatientInfo = patientInfo;
    },
    hideIgnoreModal() {
      this.$bvModal.hide("ignorePatient");
      this.selectedIgnorePatientInfo = null;
    },
    pluralize(amount, singular, plural = `${singular}s`) {
      return amount === 1 ? singular : plural;
    },
    async onMarkComplete() {
      this.isProcessing = true;

      await this.$store.dispatch("prescription/markFollowupComplete", {
        patientid: this.selectedFollowupPatientInfo.patientid,
        ids: this.selectedFollowupPatientInfo.patientfollowupid,
        followupactionnotes: this.followupNotesComplete,
        followupactionid: this.actionTaken.code,
      });

      this.$store.dispatch("dashboard/getDashboardData");

      this.$bvModal.hide("markcomplete");
      this.followupNotesComplete = "";
      this.selectedFollowupPatientInfo = null;
      this.isProcessing = false;
    },
    onIntakeError(errorMessage) {
      this.dashboardErrorMessage = errorMessage;
    },
    snoozedText(patient) {
      switch (patient?.status) {
        case "Snoozed":
          return "Snoozed for 24 hours";
        case "Requested Blossom":
          return "Request: Blossom";
        case "Requested Estriol Cream with Tretinoin":
          return "Request: Estriol Cream with Tretinoin";
        default:
          return "";
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.intervalFunction) {
      clearInterval(this.intervalFunction);
    }
    next();
  },
};
</script>

<style scoped>
.card-dashboard-list {
  height: calc(100vh - 250px);
  max-height: calc(100vh - 250px);
  overflow-y: auto;
}
</style>
