<template>
  <div class="recent-view tw-relative">
    <b-card
      no-body
      style="background-color: transparent; border: 0; margin-bottom: 0"
    >
      <template v-slot:header>
        <h4 class="mb-0 bold" style="letter-spacing: 1px">
          Recently viewed patients
        </h4>
      </template>
      <b-list-group>
        <b-list-group-item v-for="(list, i) in lists" :key="i">
          <b-row align-v="center">
            <b-col cols="auto" class="pr-0">
              <b-avatar variant="primary" :src="list.img"></b-avatar>
            </b-col>
            <b-col class="pr-0">
              <h4 class="mt-0 mb-0">{{ list.name }}</h4>
              <router-link
                :to="`/patients/profile/${list.id}`"
                @click.native="viewProfile(list.id)"
              >
                <small>View Profile</small>
              </router-link>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-card>
    <slot />
  </div>
</template>

<script>
export default {
  name: "recent-view",
  props: {
    lists: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    viewProfile(id) {
      localStorage.setItem("patientId", id);
    },
  },
};
</script>

<style lang="scss" scoped>
$class: ".recent-view";

.darkmode #{$class} {
  background-color: #000;

  .list-group-item {
    background-color: #121212;

    h4 {
      color: $white;
    }
  }
}

#{$class} {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 65px);
  right: -1.5rem;
  padding: 1.5rem;
  background-color: $quaternary;
  transition: background-color ease-in-out 0.5s, border-color ease-in-out 0.5s;

  .card-header {
    border-radius: 0;
    background-color: transparent;
    color: $secondary;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    padding-left: 0;
    border-color: $secondary;
  }

  .card > .list-group:last-child {
    border-radius: 0;
  }

  .list-group {
    max-height: calc(100vh - 270px);
    overflow: auto;
    border-radius: 0;
  }

  .list-group-item {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background-color: rgba($secondary, 0.05);
    border-color: rgba($secondary, 0.25);
    margin-bottom: 0;
    transition: background-color ease-in-out 0.5s, border-color ease-in-out 0.5s;

    h4 {
      color: $text-default;
    }
  }
}

@media (max-width: 992px) {
  #{$class} {
    padding: 0;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    min-height: auto;
    right: 0;

    .card {
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 0;
    }
  }
}
</style>
