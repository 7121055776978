<template>
  <div class="card-dashboard">
    <div class="card-counter">
      <div
        class="
          tw-py-3
          tw-text-primary
          tw-capitalize
          tw-border
          tw-border-solid
          tw-border-primary
          tw-border-t-0
          tw-border-l-0
          tw-border-r-0
          tw-flex
          tw-justify-between
          tw-relative
        "
      >
        <span>
          {{ title }}
          <small class="text-muted" v-if="text">{{ `(${text})` }}</small>
        </span>

        <template v-if="dropdown">
          <span v-click-outside="onClickOutside">
            <b-icon
              icon="caret-down-fill"
              class="tw-cursor-pointer"
              @click="show = !show"
            ></b-icon>
            <ul
              v-if="show"
              class="
                card-dropdown
                list-unstyled
                tw-absolute
                tw-left-0
                tw-right-0
                tw-top-auto
                tw-mb-0
                tw-mt-[13px]
                tw-shadow-md
                tw-z-20
              "
            >
              <li v-for="(filter, i) in options" :key="i">
                <input
                  :id="'filter' + i"
                  type="radio"
                  name="filter"
                  v-model="selected"
                  :value="filter.value"
                  @change="onChange(filter)"
                />
                <label
                  oncontextmenu="return false;"
                  :for="'filter' + i"
                  class="tw-w-full tw-py-4 tw-px-3 tw-mb-0 tw-select-none"
                >
                  {{ filter.text }}
                </label>
              </li>
            </ul>
          </span>
        </template>
      </div>

      <div
        class="
          display-2
          mb-0
          heading
          tw-text-primary
          tw-text-[44px]
          tw-leading-[40px]
          tw-tracking-[0.01em]
          tw-py-8
          tw-px-2
        "
      >
        <b-skeleton v-if="isBusy" width="60px" height="65px"></b-skeleton>
        <span v-else class="mb-0">{{ counter }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    dropdown: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    options: {
      type: [Object, Array],
    },
    counter: {
      type: Number,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      selected: "",
      text: "",
    };
  },
  created() {
    if (this.options?.length > 0) {
      this.selected = this.options[0].value;
      this.text = this.options[0].text;
      this.$emit("update:filter", this.options[0].value);
    }
  },
  methods: {
    onChange({ value, text }) {
      this.show = false;
      this.selected = value;
      this.text = text;
      this.$emit("update:filter", value);
    },
    onClickOutside() {
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
$primary: #ccffff;
.card-dropdown {
  background-color: #fff;
}

.card-dropdown li {
  position: relative;

  input {
    position: absolute;
    visibility: hidden;
    left: -999px;

    &:checked {
      + label {
        color: #1a6a72;
        background-color: #ffecca;
      }
    }
  }

  label {
    color: $text-default;
    cursor: pointer;

    &:hover {
      color: #1a6a72;
      background-color: #ffecca;
    }
  }
}

.darkmode .card-dropdown li {
  input {
    &:checked {
      + label {
        color: $primary;
        background-color: #ff8d40;
      }
    }
  }

  label {
    color: #fff;

    &:hover {
      color: $primary;
      background-color: #ff8d40;
    }
  }
}

.darkmode .card-counter .tw-text-primary {
  color: $primary;
}

.darkmode .tw-border-primary {
  border-color: $primary;
}
</style>
